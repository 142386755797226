import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import TitleIconColumns from "../components/TitleIconColumns"
import TwoColumn from "../components/TwoColumn"
import CTA from "../components/CTA"

export const query = graphql`
  query WhatWeDo {
    page: allWpPage(filter: { slug: { eq: "what-we-do" } }) {
      edges {
        node {
          nodeType
          pageTitle: title
          acfSubPageBanner {
            sloganText: subPageBannerSloganText
            sloganTitle: subPageBannerSloganTitle
            subPageBannerImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          acfCta {
            ctaShow
            ctaText
            ctaLink1 {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
          acfWhatwedo {
            whatWeDoIntroductionTitle
            whatWeDoIntroductionTitleTagline
            whatWeDoOption {
              whatWeDoText
              whatWeDoLink
              whatWeDoIcon {
                localFile {
                  publicURL
                }
              }
            }
            wwdTwoColumnRow {
              description: wwdTwoColDescriptionContent
              mainTitle: wwdTwoColMainTitle
              subTitle: wwdTwoColSubTitle
              buttonTextLink: wwdTwoColButtonTextLink {
                target
                title
                url
              }
              twoColImage: wwdTwoColImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 680) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ...SEO
        }
      }
    }
  }
`

const WhatWeDoPage = ({ location, data }) => {
  const {
    seo,
    acfSubPageBanner,
    acfCta,
    acfWhatwedo,
  } = data.page.edges[0].node

  return (
    <Layout path={location.pathname}>
      <SEO data={seo} />

      <SubBanner
        mainTitle={acfSubPageBanner.sloganTitle}
        subTitle={acfSubPageBanner.sloganText}
        image={acfSubPageBanner?.subPageBannerImage?.localFile?.childImageSharp?.fluid}
      />

      <TitleIconColumns
        subTitle={acfWhatwedo.whatWeDoIntroductionTitleTagline}
        mainTitle={acfWhatwedo.whatWeDoIntroductionTitle}
        items={acfWhatwedo.whatWeDoOption}
        iconFieldName="whatWeDoIcon"
        textFieldName="whatWeDoText"
        fieldLink="whatWeDoLink"
      />

      <TwoColumn data={acfWhatwedo.wwdTwoColumnRow} />

      {acfCta.ctaShow && (
        <CTA
          text={acfCta.ctaText}
          buttonOne={acfCta.ctaLink1}
          buttonTwo={acfCta.ctaLink2}
        />
      )}
    </Layout>
  )
}

export default WhatWeDoPage
